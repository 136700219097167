
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { Financing } from '@/entities/loans'
  import { fixPrice } from '@/utils/general'

@Component({
  methods: { fixPrice },
})
  export default class FinancingHeader extends Vue {
  @Prop({ type: Number, required: true, default: 0 }) pie!: Number;
  @Prop({ type: Object, required: true }) financing!: Financing;
  @Prop({ type: String, default: 'mdi-credit-card-outline' }) icon!: string;

  get status () {
    const { financing: { evaluation } } = this

    return evaluation?.evaluation?.closingReason?.color || evaluation.status.color
  }

  get amount () {
    const { financing: { initial, warrantyAmount } } = this

    return fixPrice(warrantyAmount - initial)
  }
  }
